<template>
  <div>
    <img alt="Vue logo" src="../assets/logo.png" class="responsive">
    <p class="text-center responsive" >Welcome to the Everwell Automation Site.</p><br/>
    <p class="text-center responsive" >Select the appropriate environment for your request!</p><br/>
    <div class="my-2">
      <v-btn small color="#195ed2" style="color: white" @click="setBetaEnv()">Beta
        <v-icon>mdi-checkbox-marked-circle</v-icon>
      </v-btn>
    </div><br>
    <div class="my-2">
      <v-btn small color="#195ed2" style="color: white" @click="setProdEnv()" :disabled="!isAdmin">Prod
          <v-icon>mdi-cloud-upload</v-icon>
      </v-btn>
    </div><br>
    <div class="my-2">
      <v-btn small color="#195ed2" style="color: white" @click="signout()">Sign Out
          <v-icon>mdi-export</v-icon>
      </v-btn>
    </div><br>
    <router-view></router-view>
  </div>
</template>

<script>
import router from './../router/index'
import { mapState, mapActions } from 'vuex'
import { routeNames } from '../../utils/dataValidations'
export default {
  computed: {
    ...mapState([
      'isAdmin',
      'isAuth'
    ])
  },
  mounted: function () {
    if (!this.isAuth) {
      router.push(routeNames.Home)
    }
  },
  methods: {
    ...mapActions([
      'removeAuthenticate',
      'setEnvironment'
    ]),
    signout: function () {
      this.removeAuthenticate()
      router.push(routeNames.Home)
    },
    setBetaEnv: function () {
      this.setEnvironment(false)
      router.push(routeNames.About)
    },
    setProdEnv: function () {
      if (!this.isAdmin){
        return
      }
      this.setEnvironment(true)
      router.push(routeNames.About)
    }
  }
}
</script>
<style scoped>
.responsive {
  max-width: 100%;
  max-height: 100;
}
</style>
